import Banner from "./Banner/Banner";
import About from "./About/About";
import Offers from "./Offers/Offers";
import Integration from "./Integration/Integration";
import FormBlock from "./FormBlock/FormBlock";
import Stages from "./Stages/Stages";
import styles from "./MainPage.module.css";
import SliderBlock from "./SliderBlock/SliderBlock";
import CalcLinkBlock from "./CalcLinkBlock/CalcLinkBlock";
import {useDispatch, useSelector} from "react-redux";
import {changeIsOpen} from "../../redux/offers-reducer";
import OfferModal from "./Offers/OfferModal/OfferModal";
import ScrollToAnchor from "./ScrollToAnchor/ScrollToAnchor";
import {Helmet} from "react-helmet-async";

const MainPage = (props) => {
    const dispatch = useDispatch();
    const offersModalIsOpen = useSelector(state => state.offers.isOpen);

    const closeOffersModal = () => {
        dispatch(changeIsOpen(false));
    }

    return (
        <section className={styles.mainPage}>
            <Helmet>
                <title>СДЭК | Заключение договора и рассчёт стоимости доставки для интернет-магазинов</title>
                <meta
                    name="description"
                    content="СДЭК для интернет-магазинов. Заключение договора со СДЭК для интернет-магазинов. Калькулятор стоимости доставки СДЭК"
                />
            </Helmet>
            {offersModalIsOpen && <div className={styles.overlay} onClick={closeOffersModal}></div>}
            <ScrollToAnchor />
            <Banner />
            <CalcLinkBlock />
            <About />
            <Offers />
            {offersModalIsOpen && <OfferModal />}
            <Integration />
            <FormBlock />
            <Stages />
            <SliderBlock />
        </section>
    )
}

export default MainPage;