import styles from "./ImportantInfoQuest.module.css";

const ImportantInfoQuest = () => {
    return (
        <div className={styles.text}>
            Заключите договор с курьерской компанией СДЭК и отправляйте по сверхвыгодному тарифу. <br/>
            Наложенный платеж. Личный менеджер. Личный кабинет для контроля отправок и финансов. <br/>
            Оставьте свои контактные данные и наш менеджер свяжется с Вами.
        </div>
    )
}

export default ImportantInfoQuest;