import styles from "./Error404.module.css";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";

const Error404 = () => {
    return (
        <section className={"container " + styles.section}>
            <Helmet>
                <title>СДЭК | 404</title>
            </Helmet>
            <h2 className={styles.title}>404</h2>
            <p className={styles.p}>Похоже, такой страницы не существует. <br/> Пожалуйста, вернитесь на <Link to="/">главную страницу</Link>.</p>
        </section>
    )
}

export default Error404;