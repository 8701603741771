import styles from "./OfferModal.module.css";
import {useDispatch, useSelector} from "react-redux";
import {changeIsOpen} from "../../../../redux/offers-reducer";
import closeIcon from "./../../../../assets/icons/close-green.svg";
import {Link} from "react-router-dom";

const OfferModal = (props) => {
    const dispatch = useDispatch();
    const modalState = useSelector(state => state.offers.modalState);

    const closeOffersModal = () => {
        dispatch(changeIsOpen(false));
    }

    return (
        <div className={styles.modal}>
            <div>
                <div className={styles.closeBlock}>
                    <button onClick={closeOffersModal} className={styles.close}>
                        <img src={closeIcon} alt="Закрыть"/>
                    </button>
                </div>
                <h3 className={styles.title}>
                    {modalState.title}
                </h3>
                <p className={styles.p} dangerouslySetInnerHTML={{ __html: modalState.content }}/>
            </div>
            <div className={styles.buttons}>
                <Link to="/#formblock" onClick={closeOffersModal} className={styles.button + " " + styles.queryButton}>Оставить заявку</Link>
                <Link to="/questionnaire" onClick={closeOffersModal}
                      className={styles.button + " " + styles.questionnaireButton}>Заполнить анкету</Link>
            </div>
        </div>
    )
}

export default OfferModal;