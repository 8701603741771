import ArticleTitle from "../../common/AtricleTitle/ArticleTitle";
import {NavLink} from "react-router-dom";
import styles from "./CalcLinkBlock.module.css";

const CalcLinkBlock = (props) => {
    return (
        <article className="container">
            <ArticleTitle title={"Рассчёт стоимости доставки"} />
            <div className={styles.calcLinkBlock}>
                <p className={styles.calcLinkText}>
                    Хотите узнать, по какому тарифу выгодней всего отправлять посылки по всей России? Рассчитайте стоимость доставки с помощью нашего калькулятора!
                </p>

                <NavLink to="/calculator" className={styles.calcLink}>
                    Рассчитать
                </NavLink>
            </div>
        </article>
    )
}

export default CalcLinkBlock;