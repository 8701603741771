import {createSlice} from "@reduxjs/toolkit";

export const offersSlice = createSlice({
    name: "Offers",
    initialState: {
        isOpen: false,
        modalState: {
            title: '',
            content: '',
        },
        items: [
            {
                id: "o1",
                name: "Маркетплейсы и VK",
                description: "Интеграция с маркетплейсами и VK",
                content: `<p>Если Вы продаете на маркетплейсах, пользуетесь CRM, CMS и пр, то подключив один из наших специально разработанных для площадок онлайн-торговли модулей, сможете создавать заказы, отслеживать их статусы в системе СДЭК, выбирать тариф и тип доставки с учетом габаритов товаров, добавлять доп. услуги (страхование, примерка на дому, частичная доставка, осмотр и т.п.), получать печатные формы накладных. </p> 
<p>Модули бывают платными и бесплатными, подключаются быстро и самостоятельно, поддерживаются командой разработчиков СДЭК и активно развиваются. <p/>
<p>Для VK мы создали специальное приложение – «СДЭК Доставка», с помощью которого заказы автоматически создаются и синхронизируются между VK и ЛК СДЭК, и Вы можете продавать через группы VK по тарифам Посылка и Экономичная Посылка, предложив бесплатную доставку покупателям до склада, двери или постамата. Подключение бесплатное, быстрое и самостоятельное. </p>
<p>Для автоматизации продаж через Telegram мы создали IT SHOP TG -  интернет-магазин и веб-приложение в Telegram с функциями чат-бота (каталог товаров с фильтром параметров и поиском, корзина с одностраничным типом оформления заказа, ЛК покупателя, уведомления о статусе заказа, чаты с покупателями, раздел управления акциями, система уведомлений о статусах заказов в Telegram и почту,аналитика (зарегистрировано/заказано/продано). </p> 
`
            },
            {
                id: "o2",
                name: "CDEK Фулфилмент",
                description: "Хранение и доставка для интернет-магазинов, отгрузка на маркетплейсы",
                content: `<p>СДЭК  фулфилмент работает по всем существующим схемам: FBO, FBS, DBS.
Выполняем полный цикл работы: заберем груз у вас/вашего поставщика, разместим на складе, подготовим к отправке и упакуем  по всем требованиям каждого маркетплейса, отвезем и сдадим на склад, либо доставим конечному покупателю. </p>
<p>Выбирайте для хранения товаров любой из наших качественных складов в более  чем 20 городах РФ и 6 странах,  комбинируйте хранение на них в разные сезоны продаж так, как вам удобно, отправляйте товар в тот город, где находится большая часть ваших клиентов, отслеживайте остатки товаров в режиме онлайн и используйте ЛК СДЭК Фулфилмента, как систему аналитики. Можно настроить интеграцию с вашей системой учета, чтобы Вы создавали заказы в привычном и комфортном интерфейсе. </p>
<p>Интеграция с маркетплейсами позволяет интернет-магазину работать по системе DBS, используя маркетплейсы  как витрину и доставляя сразу до конечного покупателя, что особенно актуально для крупногабаритного товара. </p>
<p>Менеджеры СДЭК  фулфилмент  помогут Вам выйти на крупнейший маркетплейс  Казахстана -  Kaspi.kz, а склады в Китае, Италии, ОАЭ, Турции, США и Армении откроют перед вашим бизнесом международные перспективы.  </p>
`
            },
            {
                id: "o3",
                name: "CDEK Документы",
                description: "Доставка деловой почты из-за рубежа в Россию",
                content: `<p>СДЭК документы — это услуга и тариф для доставки бизнес-корреспонденции по России и странам ЕАЭС, созданный специально для упрощения доставки деловой почты (договоры и контракты, письма и открытки, буклеты и прайс-листы, каталоги и пр.) до 0.5 кг.  </p>
<p>Количество  отправлений не ограничено, доступны все режимы доставки (склад-склад, дверь-дверь, склад-дверь, дверь-склад), упаковка предоставляется бесплатно  - фирменный зеленый конверт формата А4, с защитой от вскрытия. </p>
<p>Функция «Реверс» сэкономит время, если нужно отправить документы взамен полученных - не нужно заполнять вторую накладную или заново вызывать курьера, просто отдайте ему отправление, когда заберете конверт. </p>
<p>Все отправления застрахованы - если документы потеряются, СДЭК возместит ущерб. </p>
<p>Срок доставки от 1 рабочего дня, получателю и отправителю пришлём уведомление о доставке в смс. </p>
`
            },
            {
                id: "o4",
                name: "CDEK Media",
                description: "Ваша видеореклама на экранах в пунктах выдачи CDEK",
                content: `<p>Вы хотите, чтобы за месяц о вас узнали 6 миллионов потенциальных клиентов? Тогда CDEK Media – то, что нужно! Именно столько людей посещает пункты СДЭК ежемесячно, и практически все они (92%) являются молодыми и платежеспособными покупателями интернет-магазинов,  и, соответственно, вашей  горячей целевой аудиторией. </p>
<p>Под ваш запрос мы изготовим персональный видеоролик, который покажут на 3000+ мониторах в пунктах СДЭК по всей стране и его гарантированно увидит каждый посетитель, т.к. среднее время  пребывания посетителя равно времени ротации блока с контентом (3 минуты) и внимание человека, ожидающего своей очереди, концентрируется на видеоконтенте на удобно расположенном мониторе. </p>

<p>За рекордно низкую цену Вы получаете наиболее эффективные вложения в рекламу: <br/>
- возможность выбора целевого таргета  аудитории по городам; <br/>
- создание персональной стратегии эфира, исходя из поставленных целей; <br/>
- 200 показов в день, 3000 экранов, 6000000  возможно новых клиентов; <br/>
- и, как следствие этого, рост продаж. </p>
`
            },
            {
                id: "o5",
                name: "CDEK Термо",
                description: "Доставка с сохранением температуры от -196 до +25 °C",
                content: `<p>Вы можете доверить СДЭКу перевозку самых капризных грузов – лекарств, биообразцов, косметики, БАДов. </p>
<p>Используя 22х-летний опыт логистики, обученный персонал, сертифицированные склады и транспорт, СДЭК гарантирует бережную перевозку терморежимных грузов с контролем температуры от +25 до -196 °С на всех этапах транспортировки. </p>
<p>СДЭКТЕРМО - это: <br/>
- помещения с климат-контролем; <br/>
- термобоксы и термоэлементы «Термо-Конт МК»; <br/>
- термодатчики Testo; <br/>
- автомобили с рефрижераторами; <br/>
- диокись углерода СО2 (сухой лед); <br/>
- сосуды Дьюара; <br/>
- жидкий азот. </p>

<p>Так как мы работаем без посредников и отвечаем за всю логистику — погрузку, доставку, разгрузку и хранение, то можем составить кратчайший маршрут, чтобы груз как можно меньше пробыл в дороге, а для защиты груза от перепадов температуры используем валидированное и сертифицированное оборудование и дополнительные холодильные блоки. </p>
<p>На протяжении всей перевозки соблюдаем режим непрерывной холодовой цепи, заказчику предоставляем температурный отчет и документальные подтверждения режима. </p>
`
            },
            {
                id: "o6",
                name: "CDEK B2B",
                description: "Международные поставки коммерческих грузов в Россию",
                content: `<p>Благодаря СДЭК Вы можете получать и отправлять документы и коммерческие грузы в 28 стран мира! </p>
<p>Беспошлинному ввозу подлежат грузы до 200 € (образцы продукции, небольшие партии товаров) и документы (коммерческие документы, инвойсы, договоры, чеки о покупке, бланки и другая деловая корреспонденция). </p>
<p>Взимается пошлина с грузов дороже 200 € (любые коммерческие партии товаров, материалы и оборудование для производства). </p>
<p>С помощью налаженных схем трансграничных перевозок мы доставляем грузы из разных уголков планеты, проводим экспортное и импортное таможенное оформление, предоставляем Вам трек-номер для отслеживания и все необходимые документы для отчетности. </p>
`
            },
            {
                id: "o7",
                name: "СDEK Site",
                description: "Платформа для создания онлайн-витрины за 1 день",
                content: `<p>Вы можете абсолютно бесплатно создать сайт для своего бизнеса на он-лайн платформе One Бизнес - это интуитивно понятный конструктор для запуска бизнеса в интернете, в который легко интегрируется доставка от СДЭК. </p>
<p>Сайт создается из готовых шаблонов - Вам остается только заполнить информацией каталог и карточки товаров. Учёт всех сделок и история заказов сохраняются в единой базе, работать с которой можно даже с телефона, а  также встроенная аналитика и интеграция с самыми популярными сервисами делают работу с сайтом максимально удобной.  </p>
<p>На One Бизнес вам доступно бесплатно создание двух сайтов с публикацией в интернете, а также инструменты для привлечения клиентов (платформа билайн.ПРОдвижение, без минимального бюджета), возможность принимать оплату с помощью интернет-эквайринга от Альфа-Банка (на партнерских условиях), чат тех.поддержки и много полезных сервисов, которые нужны для развития бизнеса онлайн. </p>
`
            },
            {
                id: "o8",
                name: "CDEK отслеживание",
                description: "Мобильное приложение для отслеживания посылки",
                content: `<p>Удобное мобильное приложение помогает оформлять, отслеживать и оплачивать посылки, а также вызвать курьера и найти ближайший офис с учетом геоданных. </p>
<p>Вы можете получать уведомления о самом важном, считать стоимость доставки, оценивать качество услуг и в любую минуту узнать, где Ваш  заказ. </p>
<p>Мобильное приложение позволит узнать основную информацию об акциях и услугах компании, ознакомиться с услугами франчайзинга, обратиться в чат поддержки, заказать обратный звонок или позвонить в call-центр СДЭК. </p>
`
            }
        ],
    },
    reducers: {
        changeIsOpen: (state, action) => {
            state.isOpen = action.payload;

            if (action.payload === false) {
                state.modalState.title = '';
                state.modalState.content = '';
            }
        },
        setModalState: (state, action) => {
            state.modalState = {...action.payload};
        }
    }
})

export const {changeIsOpen, setModalState} = offersSlice.actions;
export default offersSlice.reducer;