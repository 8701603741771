import styles from "./TariffsItem.module.css";

const TariffsItem = (props) => {
    let state = props.el;
    return (
        <tr className={state.tariff_name.toLowerCase().includes("посылка склад-склад") ? styles.bold : ""}>
            <td>
                <div>{state.tariff_name}</div>
                {state.tariff_name.toLowerCase().includes("посылка") ?
                    <div>(только для ИП, ООО и самозанятых)</div> : null}
            </td>
            <td>{state.delivery_sum + " "}руб.</td>
            <td>{props.insuranceCost + " "}руб.</td>
            <td>от {" " + state.period_min + " "} до {" " + state.period_max + " "} раб. дней</td>
            <td>{state.total_delivery_cost + " "} руб.</td>
        </tr>
    )
}

export default TariffsItem;