import styles from "./Banner.module.css";
import  banner from "./../../../assets/background.png";
import {NavLink} from "react-router-dom";
const Banner = (props) => {
    return (
        <article className={styles.banner} style={{ backgroundImage: `url(${banner})` }}>
            <div className={"container " + styles.bannerWrapper}>
                <div className={styles.bannerContent}>
                    <div className={styles.bannerText}>
                        <h2 className={styles.bannerTitle}>
                            Заключите договор
                        </h2>
                        <h3 className={styles.bannerSubtitle}>
                            и отправляйте посылки с выгодой до 60%!
                        </h3>
                        <p>
                            Спецтарифы СДЭК позволят вам значительно снизить расходы на логистику и повысит лояльность ваших покупателей.
                        </p>
                        <p className={styles.bannerTextGreen}>
                            Тариф "ПОСЫЛКА" от <span className={styles.bannerTextRed + " " + styles.biggerText}>330 руб.</span> <span className={styles.biggerText}>150 руб.</span>
                        </p>
                        <p>
                            1000 руб. на старте для ваших отправок.
                        </p>
                    </div>
                    <NavLink to="/contract" className={styles.bannerLink}>
                        Оставить заявку на договор
                    </NavLink>
                </div>
            </div>
        </article>
    )
}

export default Banner;